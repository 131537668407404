<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>运营管理</a-breadcrumb-item>
    <a-breadcrumb-item>礼品兑换记录</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 日期范围： -->
    <span class="tool-title">兑换日期：</span>
    <a-range-picker
      v-model:value="dateRangeModel"
      :format="dateFormat"
    />
    <!-- 礼品类型： -->
    <span class="tool-title">礼品类型：</span>
    <a-select v-model:value="typeModel" style="width: 160px;" placeholder="请选择">
      <a-select-option :value="0">全部</a-select-option>
      <a-select-option
        v-for="(item, index) in GiftTypes"
        :key="item.value"
        :value="item.value"
      >
        {{ item.text }}
      </a-select-option>
    </a-select>
    <!-- 状态 -->
    <span class="tool-title">状态：</span>
    <a-select v-model:value="statusModel" style="width: 120px;" placeholder="请选择">
      <a-select-option :value="0">全部</a-select-option>
      <a-select-option
        v-for="(item, index) in GiftStatusTypes"
        :key="item.value"
        :value="item.value"
      >
        {{ item.text }}
      </a-select-option>
    </a-select>
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="touchSearch">查询</a-button>
    <a-button style="margin-left: 16px; margin-right: 16px;" @click="touchReset">重置</a-button>
    <!-- 弹簧 -->
    <div style="flex: 1;"></div>
    <!-- 操作 -->
    <a-button @click="touchDownload">导出数据</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="false"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'status_text'">
        {{ record[column.key] }}
        <a-tooltip v-if="record.message">
          <template #title>{{ record.message }}</template>
          <info-circle-outlined />
        </a-tooltip>
      </template>
      <!-- 操作 -->
      <template v-else-if="column.key === 'operation'">
        <span  v-if="record.status !== 3">-</span>
        <!-- <a
          v-if="record.status === 3"
          class="operation-item" 
          @click="again(record)"
        >再次提交</a> -->
        <a-popconfirm
          v-if="record.status === 3"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(record)"
          @cancel="cancel(record)"
        >
          <template #icon>
            <a-textarea
              style="width: 200px;"
              v-model:value="failedReason"
              placeholder="请输入失败原因"
              :auto-size="{ minRows: 2, maxRows: 2 }"
            />
          </template>
          <a class="operation-item" @click="touchFailed(record)">兑换失败</a>
        </a-popconfirm>
      </template>
      <!-- 操作 -->
      <template v-else>
        {{ record[column.key] || '-' }}
      </template>
    </template>
  </a-table>
  <!-- 自定义分页器 -->
  <a-pagination
    v-if="dataSource.length"
    style="margin: 16px 0; text-align: right;"
    v-model:current="pagination.current"
    v-model:pageSize="pagination.pageSize"
    :total="pagination.total"
    :showSizeChanger="pagination.showSizeChanger"
    @change="handlePaginationChange"
  />
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message, Modal } from 'ant-design-vue'
import * as Ex from "@netjoy/excelex"
import Pub from '@/utils/public'
import dayjs from 'dayjs'
import { GiftTypes, GiftStatusTypes } from '@/utils/constantList'
import { giftOrderList, giftSaveMsg } from '@/api/copyright'

// 加载
let isLoading = ref(false)
// 类型
let typeModel = ref(undefined)
let type = ref(undefined)
// 日期范围
const dateFormat = 'YYYY-MM-DD'
let dateRangeModel = ref([dayjs().subtract(30, 'day'), dayjs()])
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 状态
let statusModel = ref(undefined)
let status = ref(undefined)
// 失败原因
let failedReason = ref(undefined)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '兑换时间',
    dataIndex: 'created_time',
    key: 'created_time'
  },
  {
    title: 'UID',
    dataIndex: 'user_id',
    key: 'user_id'
  },
  {
    title: '归属项目',
    dataIndex: 'project_name',
    key: 'project_name'
  },
  {
    title: '礼品',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '手机号码',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: '供应商订单号',
    dataIndex: 'trade_no',
    key: 'trade_no'
  },
  {
    title: '状态',
    dataIndex: 'status_text',
    key: 'status_text'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 初始化选择项列表
  getSelectList()
  // 获取推广数据列表
  getList()
})

// 模糊匹配
function filterOption (input, option) {
  console.log(option)
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 分页处理
function handlePaginationChange (current, pageSize) {
  pagination.current = current
  pagination.pageSize = pageSize
  getList()
}

// 查询
function touchSearch () {
  // 调整参数
  type.value = typeModel.value
  dateRange.value = dateRangeModel.value
  status.value = statusModel.value
  pagination.current = 1
  // 获取列表
  getList()
}

// 重置
function touchReset () {
  dateRangeModel.value = [dayjs().subtract(30, 'day'), dayjs()]
  statusModel.value = undefined
  status.value = undefined
  typeModel.value = undefined
  type.value = undefined
  // 初始化获取
  touchSearch()
}

// 获取列表
function getList () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    gift_type: type.value,
    status: status.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  giftOrderList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 再次提交
function again () {
  Modal.confirm({
    title: '提示',
    content: '确认要进行该操作吗？',
    cancelText: '取 消',
    okText: '确 定',
    onOk: () => {
      // isLoading.value = true
      // recommendDelete({ id: record.id }).then(res => {
      //   isLoading.value = false
      //   const { code, msg, data } = res
      //   if (code === 0) {
      //     message.success('删除成功')
      //     getList()
      //   } else {
      //     message.error(res.message || msg)
      //   }
      // }).catch(() => {
      //   message.error('删除失败')
      // })
    }
  })
}

// 兑换失败
function failed () {

}

function touchFailed(record) {
  failedReason.value = undefined
}

function confirm (record) {
  isLoading.value = true
  giftSaveMsg({ id: record.id, message: failedReason.value }).then(res => {
    isLoading.value = false
    if (res.code === 0) {
      message.success('提交成功')
      getList()
    } else {
      message.error('提交失败')
    }
  }).catch(() => {
    isLoading.value = false
    message.error('提交失败')
  })
}

function cancel () {
}

// 初始化选择项列表
function getSelectList () {
  // dyAgentName().then(res => {
  //   const { code, data } = res
  //   if (code === 0) {
  //     termediarys.value = data
  //   }
  // })
}

// 点击导出数据
function touchDownload () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    gift_type: type.value,
    status: status.value,
    page: 1,
    page_size: 10000
  }
  giftOrderList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      // 将汇总数据插入第一条
      const list = data.data
      if (list.length) {
        const columns = [
          {
            name: '兑换时间',
            field: 'conversion_success_time'
          },
          {
            name: 'UID',
            field: 'user_id'
          },
          {
            name: '归属项目',
            field: 'project_name'
          },
          {
            name: '礼品',
            field: 'name'
          },
          {
            name: '手机号码',
            field: 'phone'
          },
          {
            name: '供应商订单号',
            field: 'trade_no'
          },
          {
            name: '状态',
            field: 'status_text'
          },
          {
            name: '失败原因',
            field: 'message'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            // 金额处理
            if (field === 'total_settle_cps' || field === 'total_settle_ad_share' || field === 'total_settle') {
              if (item.data != '-') {
                item.data = Pub.KEEP_NUMBER_DECIMAL(item.data / 1000, 2, false, true)
              }
              return item
            }
          }
          // 无数据兼容
          // item.data = item.data || 0
          return item
        },  `运营管理-星图融合任务`)
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error('导出失败')
    }
  }).catch(() => {
    message.error('导出失败')
  })
}
</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>